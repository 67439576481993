const module = {
  checkPermissions (role, route) {
    let routeRoles

    try {
      routeRoles = route.meta.permissions.roles
    } catch (error) {
      routeRoles = ['*']
    }

    if (routeRoles.includes('*')) {
      return true
    }

    if (routeRoles.includes(role)) {
      return true
    } else {
      if (role.indexOf(':') !== -1) {
        const roleComplex = role.split(':')

        if (routeRoles.includes(roleComplex[0])) {
          return true
        }
      }
    }

    return false
  }
}

export default module
