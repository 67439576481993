/* eslint-disable */

const module = {
  decodeBase64: s => {
    let e = {}
    let i
    let b = 0
    let c
    let x
    let l = 0
    let a
    let r = ''
    let w = String.fromCharCode
    let L = s.length
    const A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

    for (i = 0; i < 64; i++) {
      e[A.charAt(i)] = i
    }

    for (x = 0; x < L; x++) {
      c = e[s.charAt(x)]
      b = (b << 6) + c
      l += 6;
      while (l >= 8) {
        ((a=(b>>>(l-=8))&0xff)||(x<(L-2)))&&(r+=w(a))
      }
    }

    return r
  },
  formatDate: date => {
    return module.padLeft(date.getDate(), 2, '0') + '.' + module.padLeft((parseInt(date.getMonth()) + 1), 2, '0') + '.' + date.getFullYear()
  },
  formatDateTime: date => {
    return module.padLeft(date.getDate(), 2, '0') + '.' + module.padLeft((parseInt(date.getMonth()) + 1), 2, '0') + '.' + date.getFullYear() + ' ' + module.padLeft(date.getHours(), 2, '0') + ':' + module.padLeft(date.getMinutes(), 2, '0')
  },
  formatDateForRequest: date => {
    return date.getFullYear() + '-' + module.padLeft((parseInt(date.getMonth()) + 1), 2, '0') + '-' + module.padLeft(date.getDate(), 2, '0') + 'T00:00:00Z'
  },
  formatDateForLog: date => {
    return date.getFullYear() + '-' + module.padLeft((parseInt(date.getMonth()) + 1), 2, '0') + '-' + module.padLeft(date.getDate(), 2, '0') + ' ' + module.padLeft(date.getHours(), 2, '0') + ':' + module.padLeft(date.getMinutes(), 2, '0') + ':' + module.padLeft(date.getSeconds(), 2, '0') + '.' + module.padLeft(date.getMilliseconds(), 3, '0')
  },
  padLeft: (string, length, char) => {
    char = char || '0'
    string = '' + string
    while (string.length < length) {
      string = char + '' + string
    }
    return string
  },
  validatePhone: phone => {
    phone = phone.replace(/[^0-9]/g ,'')
    return phone.length == 11 && (phone.substr(0, 2) === '79' || phone.substr(0, 2) === '77') || phone.length == 12 && phone.substr(0, 3) === '375'
  },
  validateEmail: email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  },
  validateSnils: value => {
    var valueClean = value.replace(/-/g, "")
    var valueFinal = valueClean.replace(" ", "")

    var checkSum = parseInt(valueFinal.slice(9), 10)

    // представить строку как массив (для старых браузеров)
    valueFinal = "" + valueFinal
    valueFinal = valueFinal.split('')

    var sum = (
      valueFinal[0] * 9 +
      valueFinal[1] * 8 +
      valueFinal[2] * 7 +
      valueFinal[3] * 6 +
      valueFinal[4] * 5 +
      valueFinal[5] * 4 +
      valueFinal[6] * 3 +
      valueFinal[7] * 2 +
      valueFinal[8] * 1
    )

    if (sum < 100) {
      return sum == checkSum
    }

    if (sum == 100 || sum == 101) {
      return checkSum == 0
    }

    if (sum > 101) {
      return (sum % 101 == checkSum || (sum % 101 == 100 && checkSum == 0))
    }
  },
  validateInn: value => {
    var valueClean = value.replace(/-/g, "")
    var valueFinal = valueClean.replace(/\s/g, "")

    // преобразуем в массив
    valueFinal = valueFinal.split('')

    //для ИНН в 10 знаков
    if ((valueFinal.length == 10) && (valueFinal[9] == ((
      2 * valueFinal[0] + 4 * valueFinal[1] + 10 * valueFinal[2]
      + 3 * valueFinal[3] + 5 * inputNumvalueFinalber[4] + 9 * valueFinal[5]
      + 4 * valueFinal[6] + 6 * valueFinal[7] + 8 * valueFinal[8])
      % 11) % 10)){
      return true
      //для ИНН в 12 знаков
    }

    if ((valueFinal.length == 12) && ((valueFinal[10] == ((
      7 * valueFinal[ 0] + 2 * valueFinal[1] + 4 * valueFinal[2]
      + 10 * valueFinal[3] + 3 * valueFinal[4] + 5 * valueFinal[5]
      + 9 * valueFinal[6] + 4 * valueFinal[7] + 6 * valueFinal[8]
      + 8 * valueFinal[9]) % 11) % 10) && (valueFinal[11] == ((
        3 * valueFinal[ 0] + 7 * valueFinal[1] + 2 * valueFinal[2]
        + 4 * valueFinal[3] + 10 * valueFinal[4] + 3 * valueFinal[5]
        + 5 * valueFinal[6] + 9 * valueFinal[7] + 4 * valueFinal[8]
        + 6 * valueFinal[9] + 8 * valueFinal[10]) % 11) % 10))){
      return true
    }
    return false
  },
  /**
   * Set cookie
   *
   * @param string name
   * @param string value
   * @param int days
   * @param string path
   * @see http://www.quirksmode.org/js/cookies.html
   */
  createCookie: (name, value, days, path) => {
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      var expires = "; expires=" + date.toGMTString()
    } else var expires = ""
    document.cookie = name + "=" + value + expires + "; path=" + path
  },
  /**
   * Read cookie
   * @param string name
   * @returns {*}
   * @see http://www.quirksmode.org/js/cookies.html
   */
  readCookie: name => {
    var nameEQ = name + "="
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  showCookieMessage: () => {
    const message = document.createElement('div')
    message.className = 'cookie-message'
    message.id = 'cookie-message'
    message.innerHTML = '\
      <div class="cookie-message__text">\
        <p>Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, пользовательских данных (сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана; источник откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес) в целях функционирования сайта, проведения ретаргетинга, проведения статистических исследований и обзоров, продвижения товаров и услуг.</p>\
        <p>Если вы не хотите, чтобы ваши данные обрабатывались, покиньте сайт.</p>\
      </div>\
      <button id="cookie-message-close-button" type="button" class="cookie-message__close-button"></button>\
    '

    const style = document.createElement('style')
    style.innerHTML = '\
      .cookie-message {\
        position: fixed;\
        bottom: 0;\
        left: 0;\
        right: 0;\
        background: rgba(255, 255, 255, 0.98);\
        z-index: 100000;\
        border-top: 1px solid rgba(0, 0, 0, 0.05);\
      }\
      .cookie-message__text {\
        padding: 16px 8%;\
        color: #333;\
        font-size: 11px;\
        font-family: sans-serif;\
      }\
      .cookie-message__text p {\
        margin: 10px 0;\
      }\
      .cookie-message__close-button {\
        -appearence: none;\
        -webkit-appearence: none;\
        -moz-appearence: none;\
        border: none;\
        background: none;\
        width: 20px;\
        height: 20px;\
        text-align: center;\
        position: absolute;\
        top: 14px;\
        right: 3%;\
        cursor: pointer;\
      }\
      .cookie-message__close-button:before,\
      .cookie-message__close-button:after {\
        content: \'\';\
        position: absolute;\
        left: 0;\
        right: 0;\
        top: 50%;\
        background: #333;\
        height: 2px;\
        margin-top: -1px;\
      }\
      .cookie-message__close-button:before {\
        transform: rotate(45deg);\
      }\
      .cookie-message__close-button:after {\
        transform: rotate(-45deg);\
      }\
      @media (max-width: 767px) {\
        .cookie-message__text {\
          padding: 0 3%;\
          font-size: 10px;\
        }\
        .cookie-message__close-button {\
          top: -32px;\
          right: 3%;\
        }\
      }\
    '

    document.body.appendChild(message)
    document.head.appendChild(style)

    var cookieMessage = document.getElementById('cookie-message')
    if (cookieMessage == null) {
      return
    }
    var cookie = module.readCookie('seen-cookie-message')
    if (cookie != null && cookie == 'yes') {
      cookieMessage.style.display = 'none'
    } else {
      cookieMessage.style.display = 'block'
    }

    var cookieMessageCloseButton = document.getElementById('cookie-message-close-button')
    cookieMessageCloseButton.addEventListener('click', function() {
      // Set/update cookie
      var cookieExpiry = cookieMessage.getAttribute('data-cookie-expiry')
      if (cookieExpiry == null) {
        cookieExpiry = 365 * 10
      }
      var cookiePath = cookieMessage.getAttribute('data-cookie-path')
      if (cookiePath == null) {
        cookiePath = "/"
      }

      module.createCookie('seen-cookie-message', 'yes', cookieExpiry, cookiePath)

      cookieMessage.style.display = 'none'
    })
  },
  numberFormat: ( number, decimals, dec_point, thousands_sep ) => {
    // Format a number with grouped thousands
    //
    // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
    // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   bugfix by: Michael White (http://crestidg.com)

    var i, j, kw, kd, km, s

    s = number < 0 ? '-' : ''

    number = Math.abs(number)

    // input sanitation & defaults
    if( isNaN(decimals = Math.abs(decimals)) ){
      decimals = 2
    }
    if( dec_point == undefined ){
      dec_point = ","
    }
    if( thousands_sep == undefined ){
      thousands_sep = "."
    }

    i = parseInt(number = (+number || 0).toFixed(decimals)) + ""

    if( (j = i.length) > 3 ){
      j = j % 3
    } else{
      j = 0
    }

    km = (j ? i.substr(0, j) + thousands_sep : "")
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep)
    // kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).slice(2) : "")
    kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "")

    return s + km + kw + kd
  },
  pluralize: function (number, one, two, five) {
    let n = Math.abs(number)
    n %= 100
    if (n >= 5 && n <= 20) {
      return five
    }
    n %= 10
    if (n === 1) {
      return one
    }
    if (n >= 2 && n <= 4) {
      return two
    }
    return five
  },
  downloadFile: function (data, filename) {
    // https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const downloadWindow = window.open()

    if (iOS) {
      downloadWindow.document.write(data)
    } else {
      const url = downloadWindow.URL.createObjectURL(new Blob([data]))
      const link = downloadWindow.document.createElement('a')
      link.href = url
      link.setAttribute('target', '_blank')
      link.setAttribute('download', filename)
      downloadWindow.document.body.appendChild(link)
      link.click()
      downloadWindow.close()
    }
  },
  closest (array, num) {
    var i = 0
    var minDiff = 10000000
    var ans
    for (i in array) {
      var m = Math.abs(num - array[i])
      if (m < minDiff) {
        minDiff = m
        ans = array[i]
      }
    }
    return ans
  },
  debounce(fn, wait){
    let timer;
    return function(...args){
      if(timer) {
        clearTimeout(timer); // clear any pre-existing timer
      }
      const context = this; // get the current context
      timer = setTimeout(()=>{
        fn.apply(context, args); // call the function if time expires
      }, wait);
    }
  }
}

export default module
