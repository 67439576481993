import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  get (store) {
    Vue.$logger.debug('task | Getting active tasks')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/user/tasks'

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        Vue.$logger.error('task | get | Error: ', error.response)

        switch (error.response.status) {
          case 401:
            throw new Error('not_authorized')
          default:
            throw new Error('general_error')
        }
      })
  },
  getSigningLink (store, taskId) {
    Vue.$logger.debug('task | Getting link for sms signing act')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/user/task/' + taskId + '/contract/link'

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        Vue.$logger.error('task | get | Error: ', error.response)

        switch (error.response.status) {
          //если ссылка еще не готова то может возвращаться 404 с кодом contract_not_found
          case 404:
            throw error
          case 401:
            throw new Error('not_authorized')
          default:
            throw new Error('general_error')
        }
      })
  }
}
