import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    items: []
  },
  mutations: {
    add: (state, payload) => {
      state.items.push(payload)
    },
    set: (state, payload) => {
      state.items = payload
    },
    clear: (state) => {
      state.items = []
    }
  },
  getters: {
    sum: state => {
      if (state.items.length) {
        return state.items.reduce((sum, item) => sum + item.price.points, 0)
      } else {
        return 0
      }
    },
    quantity: state => {
      return state.items.length
    }
  },
  actions: {
    add ({ commit }, payload) {
      Vue.$logger.info('cart | Add: ', JSON.parse(JSON.stringify(payload)))
      commit('add', payload)
      this.dispatch('cart/sync')
    },
    clear ({ commit }) {
      Vue.$logger.debug('cart | Clear')
      commit('clear')
      this.dispatch('cart/sync')
    },
    sync (store) {
      localStorage.setItem('cart', JSON.stringify(store.state.items))
    },
    syncFromStorage ({ commit }) {
      if (localStorage.getItem('cart')) {
        commit('set', JSON.parse(localStorage.getItem('cart')))
      }
    }
  }
}
