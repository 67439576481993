import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  refresh (store, callback) {
    Vue.$logger.debug('auth-token | refresh')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/auth/refreshToken'

    return axios.put(ACTION_URL, {}, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        Vue.$logger.info('auth-token | New token: ' + response.data.token)
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        Vue.$logger.error('auth-token | Error: ' + error.response.data)
      })
  },
  login (store, credentials) {
    Vue.$logger.debug('auth-token | Signing in with token')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/auth/token/login'

    return axios.post(ACTION_URL, credentials)
      .then((response) => {
        Vue.$logger.info('auth-token | User token: ' + response.data.token)
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('auth-token | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('wrong_token')
          } else if (error.response.data.code) {
            throw new Error(error.response.data.code)
          } else {
            throw new Error('auth_error')
          }
        } else if (error.request) {
          Vue.$logger.error('auth-token', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
