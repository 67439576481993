import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  get (store, callback) {
    Vue.$logger.debug('balance | get')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/user/balance'

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        Vue.$logger.info('balance | Balance: ' + response.data.balance)
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        Vue.$logger.error('balance | Error: ' + error.response.data)
      })
  }
}
