import Vue from 'vue'
import task from 'base/api/task'


const testItems = [
  {
    "id": 1,
    "type": "approvement_request",
    "uid": "me@giftery.ru",
    "status": "done",
    "meta": {},
    "created_at": "2023-11-21T15:28:14+03:00",
    "docs_uploaded_at": null,
    "done_at": null,
    "reviewer": null,
    "updated_at": null
},
{
  "id": 2,
  "type": "act_request",
  "uid": "me@giftery.ru",
  "status": "pending",
  "meta": {},
  "created_at": "2023-11-21T15:28:14+03:00",
  "docs_uploaded_at": null,
  "done_at": null,
  "reviewer": null,
  "updated_at": null,
  "signing_method": "sms",
  "signing_link": "https://"
}
] 
export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    setTasks: (state, payload) => {
      state.items = payload
    }
  },
  getters: {
    isUserApproved: state => {
      const items = state.items || []
      const approvedTask = items.find(task => task.type === 'approvement_request' && task.status === 'done')
      return !!approvedTask
    },
    filtered: (state, getters) => {
      if (getters.isUserApproved) {
        return state.items
      }

      return state.items.filter(task => task.type === 'approvement_request')
      
    },
    active: state => {
      const items = state.items || []
      return items.filter(task => ['pending'].includes(task.status))
    },
    pending: (state, getters) => {
      const items = getters.filtered || []
      return items.filter(task => ['pending'].includes(task.status))
    },
    all: state => {
      const items = state.items || []
      return items
    }
  },
  actions: {
    get ({ commit }) {
      Vue.$logger.debug('user | Getting tasks')

      return new Promise((resolve, reject) => {
        task.get(this)
          .then(function (response) {
            commit('setTasks', response.data)
            resolve()
          }).catch(function () {
            reject(new Error('user | tasks.getActive | ошибка'))
          })
      })
    }
  }
}
